
/* LANDING PAGE */

body#landing {
    section.row.landing {
        padding: 0;
        margin: 0px 0 16px 0;
        img.bg {
            z-index: -1;
            width: 100%;
           clear: none;
           margin: 0;
        }

//       h3 {
//         font-family: 'Raleway', sans-serif;
////         margin-bottom: - rem-calc(40);
//          font-size: rem-calc(36);
//          color: $black;
//          z-index: 100;
//          float: left;
//          clear: none;
//          display: inline;
//       }
//
//       h4 {
//         font-family: 'Raleway', sans-serif;
//          font-size: rem-calc(52);
//         margin-top: - rem-calc(60);
//         text-align: right;
//          color: $white;
//       }
    }

    img.rounder {
        border-radius: rem-calc(100);
    }

    div.thumbnail > h4 {
        margin-top: -40px;
        margin-left: 4px;
        text-align: left;
         a {
            background-color:rgba(255,255,255, 0.8);
            padding: 4px;
        }
    }
}
