@charset 'utf-8';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import 'components/nav';
@import 'landing';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include motion-ui-transitions;
@include motion-ui-animations;



/* Generic header declaration */

h1, h2, h3, h4, h5 {
		color: $secondary-color;
}


/* Page Title */

h1.pagetitle {
		color: $primary-color;
		font-weight: bold;
		font-size: calc-rem(20);
		font-variant: small-caps;
		margin: 8px 0 -12px 8px;
		font-family: 'Lora', serif;
}

section.jumbotron,
div.jumbotron {
		background-color: #4f6573;
		color: $white;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
		border-radius: 25px;
		margin: 10px auto;
		padding: 16px;
		h2,
		h3,
		h4 {
				text-align: center;
				font-weight: bold;
				color: $white;
				font-family: 'Lora', serif;
		}
		h3 {
				text-transform: uppercase;
//        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
				color: $black;
			 font-size: rem-calc(36);
		}
		h4 {
				color: $white;
				font-size: 20px;
				line-height: 32px;
				//        font-weight: lighter;
				//        text-shadow: 1px 1px 4px rgba(0,0,0,0.5);
		}
		label {
				color: $white;
				text-align: center;
				//        font-style: italic;
				display: block;
				background-color: rgba(0, 0, 0, 0.3);
				font-family: 'Lora', serif;
		}
		img {
				width: 100%;
				margin-top: 10px;
				//        border-radius: 15px;
		}
}

.rt01flat>.rt01tabs.rt01pag-hor.rt01pag-begin, .rt01flatbox>.rt01tabs.rt01pag-hor.rt01pag-begin,
div.rt01.rt01flatbox div.rt01tabs.rt01pag-hor {
		border-bottom-color: $primary-color;

}

div.rt01.rt01flatbox {
	div.rt01pag.rt01tbs {
		border-bottom-color: $primary-color;
	}
	div.rt01pagitem.rt01cur {

		background-color: $primary-color;

	}
}

div.rt01 div.rt01tabs
div.rt01pagitem {
		font-size: rem-calc(15);
//		padding: 8px 8px;
		text-decoration: none;
		font-variant: small-caps;
		font-weight: bold;

}
.tabs-title {
		border-right: 1px solid $lighter-tertiary-color;
}

.tabs-title > a {
		font-size: rem-calc(15);
		padding: 8px 8px;
		text-decoration: none;
		font-variant: small-caps;
		font-weight: bold;
}

.tabs-title > a:hover,
.tabs-title > a:focus,
.tabs-title > a[aria-selected="true"] {
		background: $primary-color;
		color: $white;
		//    font-weight: bold;
		font-size: rem-calc(16);
}

div.headline {
		box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.25);
		border: none;
}

section.row,
footer.row {
		padding: 16px;
}

footer.row {
		background-color: $ctertiary-color;
		margin-top: 24px;
		margin-bottom: 24px;
		padding: 14px;
		h4 {
				color: $lighter-ctertiary-color;
				text-align: center;
		}
		p {
			color: $lighter-csecondary-color;
			margin-top: 1em;
	 }
}

.detail {
		// box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.25);
		h3 {
				color: $primary-color;
		}
		img {
				width: 100%;
				margin-top: 10px;
				//        border-radius: 15px;
		}
}

div.tab-toolbar-container {
		visibility: hidden;
}

.tabs-content > img {
		width: 100%;
}

label.figurecaption {
		color: $white;
		text-align: center;
		//        font-style: italic;
		display: block;
		background-color: rgba(0, 0, 0, 0.5);
		margin-bottom: 12px;
		cite {
			color: $lighter-csecondary-color;
			margin-right: 10px;
			text-align: right;
	 	}
}

a.references {
	 font-variant: small-caps;
	 float: right;
}

.no-decoration {
	 list-style: none;
	 margin: 0;
}

.lightbox {
	display: none;
	min-width: 500px;

	img {
		max-height: 75vh;
	}
}

.jumboimage {
	cite {
		color: $lighter-csecondary-color;
		margin-right: 10px;
		text-align: right;
 }
}

/* For Responsive Videos */

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* For images (of different heights) to have same height when next to each other
	 This is done by using flex display and by setting each img flex value to the
	 aspect ratio of the image.

	 This is put inside of a media query in order to switch to default style when
	 on 'small' devices.
*/
@include breakpoint(medium large) {
	 .row-img {
			display: flex;

			// p2-5
			.img-goa {
				flex: 1.6;
			}
			.img-bsai {
				flex: 1.6;
			}
			// p3-1
			.img-aleutian {
				 flex: 1.29375;
			}
			.img-texas {
				 flex: 1.1291;
			}
			// p3-3
			.img-modtrawl {
				 flex: 1.5737;
			}
			.img-footrope {
				 flex: 0.75;
			}
			// p3-5
			.img-camera {
				 flex: 1.3;
			}
			.img-canyon {
				 flex: 1.5;
			}
			// p4-2
			.img-walrus {
				 flex: 1.5;
			}
			.img-protection {
				 flex: 1.3389;
			}
			// p6-1
			.img-whale {
				 flex: 1.5;
			}
			.img-alaskamap {
				 flex: 1.2934;
			}
			//p7-4
			.img-fishermen {
				 flex: 2.1325;
			}
			.img-villages {
				 flex: 1.3652;
			}
			.img-workers {
				 flex: 0.8262;
			}
			.img-salmoncamp {
				 flex: 1.3597;
			}
			.img-snigaroff {
				flex: 1.8962;
			}
			.img-aggregate {
				flex: 1.5848;
			}
	 }
}
